#bsPhotoGalleryModal .modal-content {
    border-radius:0;
}
#bsPhotoGalleryModal .modal-dialog img {
    text-align:center;
    margin:0 auto;
    width:100%;
}
#bsPhotoGalleryModal .modal-body {
    padding:0px !important;
}
#bsPhotoGalleryModal .bsp-close {
  position: absolute; 
  right: -14px; 
  top: -11px; 

  color:#fff; 
  text-shadow: 1px 1px 18px #000;
  font-size: 30px;
  width: 30px;
    height: 30px;
    
}

#bsPhotoGalleryModal .bsp-close:hover {
  cursor: pointer;
  opacity:.6;
  text-shadow: none;
  
}
.bspHasModal {
  cursor: pointer;
}
.bspHasModal .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.imgWrapper {
  overflow: hidden;
  max-height: 200px;
}

a.bsp-controls, 
a.bsp-controls:visited, 
a.bsp-controls:active {
  position: absolute;
  top: 44%;
  font-size: 26px;
  color: #fff;
  text-shadow: 1px 1px 18px #000;
}
a.bsp-controls.next {
  right:-10px;
}
a.bsp-controls.previous {
  left:-10px;
}
a.bsp-controls:hover {
  opacity:.6;
  text-shadow: none;
}
.bsp-text-container {
  clear:both;
  display:block;
  padding-bottom: 5px;
}
#bsPhotoGalleryModal h6{
  margin-bottom: 0;
  font-weight: bold;
  color: #000;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 5px;
}
#bsPhotoGalleryModal .pText {
 font-size: 13px;
margin-bottom: 0px;
padding: 0 12px;
color: #000;
font-weight: bold;
line-height: 15px;
padding-top: 10px;
} 
#bsPhotoGalleryModal .pText2 {
 font-size: 12px;
margin-bottom: 0px;
padding: 0 12px 5px;
color: #2c2c2c;

} 
  

@media screen and (max-width: 380px){
   .col-xxs-12 {
     width:100%;
   }
   .col-xxs-12 img {
     width:100%;
   }
}